import React from 'react'
import { useSelector } from 'react-redux'

import CaseList from '../components/cases/CaseList'
import ClaimList from '../components/cases/ClaimList'
import Layout from '../components/layouts/Layout'
import MainBox from '../components/common/MainBox'
import { isVet } from '../lib/helpers'
import { usersSelector, UsersState } from '../hasura/slices/users'

export default function Cases() {
  const { user, role }: UsersState = useSelector(usersSelector)

  const dark = isVet(role) && Boolean(user?.dark_mode_on)

  return (
    <Layout>
      <MainBox dark={dark} defaultPadding>
        {user ? isVet(role) ? <ClaimList dark={dark} /> : <CaseList /> : null}
      </MainBox>
    </Layout>
  )
}
