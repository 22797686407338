import React, { useEffect, useMemo, useState } from 'react'
import first from 'lodash/first'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import partition from 'lodash/partition'
import { Badge } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useIntercom } from 'react-use-intercom'
import { useTable, useSortBy } from 'react-table'

import CONFIG from '../../config'
import ConsultationCell from './ConsultationCell'
import MedicalImageThumbnail from '../common/MedicalImageThumbnail'
import SamePatientLink from './SamePatientLink'
import SwitchPatientModal from './SwitchPatientModal'
import { AiMode } from '../../interfaces/AiMode'
import { Cases_cases, Cases_cases_consultations, Cases_cases_medical_images } from '../../hasura/graphQlQueries/types/Cases'
import { OrganizationEvent } from '../../lib/organizationEventTypes'
import { Table, Header, HeaderCell, Cell, TextCell, NoResults, Row, isColumn } from '../../components/common/Table'
import { TableSort } from '../common/TableSort'
import { consultationsSelector, ConsultationsState, Patient } from '../../hasura/slices/consultations'
import { createCustomerPortalAction } from '../../hasura/slices/billing'
import { emojiFor, speciesFor, isUser, ohifStudyUrl, isDemoOrg, isExotic, pluralize, isAdmin } from '../../lib/helpers'
import { getMaxSeverity, getRisk, gpFilteredPredictions } from '../../lib/aiHelpers'
import { imageAndVideoCountsForUltrasound, isUltrasoundCase, isXrayCase } from '../../lib/modalityHelpers'
import { insertOrganizationEventAction } from '../../hasura/slices/organizations'
import { parseDate } from '../../lib/timeHelpers'
import { usersSelector, UsersState } from '../../hasura/slices/users'

// @ts-ignore
import add from '../../lib/images/add.svg'
// @ts-ignore
import moveArrow from '../../lib/images/right-arrow-no-tail.svg'

const defaultColumn = {
  Cell: TextCell,
}

interface Props {
  blurAiFindings: boolean
  data: Cases_cases[]
  fetchCases: () => void
  handleClickedAiAssessment: (id: number) => void
  isUser: boolean
  organizationId?: number
  shouldPopBillingModal: boolean
  sortEnabled: boolean
  stripeId: string | null | undefined
  uploadDicom: () => void
  viewConsultationReport: (consultation: Cases_cases_consultations, key: string, patient: Patient, privateNotes?: string) => void
}

const getIsEquipmentFromCase = (c: Cases_cases) => {
  const images = c.medical_images.map((m) => m.id)
  const isEquipmentCount = c.patient.is_equipment_patient_events.filter((i) => images.includes(i.additional_data['medical_image_id']))
    .length
  return isEquipmentCount / images.length > 0.5
}

const MAX_IMAGES_PER_ROW = 8

interface YoloResult {
  label: string
  result: boolean
}

const imageIsAiComplete = (m: Cases_cases_medical_images) => {
  const IMAGE_CREATED_MINUTES_AGO = 10
  const createdMinutesAgo = Math.abs(moment(m.created_at).diff(moment(), 'minutes'))
  const createdMoreThanMinutesAgo = createdMinutesAgo > IMAGE_CREATED_MINUTES_AGO
  const hasYoloResults = m.yolo_results_denormalized !== null
  const yoloResults = (m.yolo_results_denormalized || []) as YoloResult[]
  const permutationLabels = m.medical_image_permutations.map((p) => p.label)
  const allPermutationsExist = hasYoloResults && yoloResults.every((r) => !r.result || permutationLabels.includes(r.label))
  return createdMoreThanMinutesAgo || allPermutationsExist
}

interface ParsedPrediction {
  condition: string
  likelihood: string
}

export default function CasesTable(props: Props) {
  const dispatch = useDispatch()

  const { trackEvent } = useIntercom()

  const { data, viewConsultationReport, blurAiFindings, stripeId } = props
  const { conditions, presignedCaseImageUrls }: ConsultationsState = useSelector(consultationsSelector)
  const { accessToken, role, user, isRadimalEnterprise, aiMode }: UsersState = useSelector(usersSelector)

  const [hoveredRow, setHoveredRow] = useState<number | undefined>()
  const [switchPatientCase, setSwitchPatientCase] = useState<Cases_cases | undefined>()

  const isOddMinute = moment().minutes() % 2 === 0
  const isErMode = aiMode === AiMode.ER

  useEffect(() => {
    setHoveredRow(undefined)
  }, [props.organizationId])

  const columns = useMemo(
    () => [
      {
        Header: 'Patient',
        accessor: (c: Cases_cases) => c,
        sortType: (a: { original: Cases_cases }, b: { original: Cases_cases }) => {
          return a.original.patient.display_name.localeCompare(b.original.patient.display_name)
        },
        Cell: (c: { value: Cases_cases }) => {
          const isEquipment = getIsEquipmentFromCase(c.value)
          const patientId = c.value.patient.dicom_server_patient_id.split('-').slice(1).join('-')
          const organizationName = c.value.dicom_server?.organization?.display_name

          return (
            <div className={'text-m m-0 d-flex align-items-center'}>
              <p className="mr-2 mb-0 text-xl">{isEquipment ? '🛠️ ' : emojiFor(speciesFor(c.value.patient.species))}</p>

              <div className="position-relative flex-grow">
                <p className="m-0">{c.value.patient.display_name}</p>
                <div style={{ top: '95%' }} className="position-absolute text-muted text-xxs text-muted hide-mobile">
                  {patientId && (
                    <div
                      className="d-flex align-items-center m-0 pointer"
                      onClick={() => setSwitchPatientCase(c.value)}
                      style={{ width: '100%', marginBottom: '0.27rem' }}
                    >
                      <p className="m-0">ID {patientId}</p>
                      <img className="icon-xxxxs ml-1" src={moveArrow} />
                    </div>
                  )}
                  {isAdmin(role) && <p style={{ lineHeight: '12px' }}>{organizationName}</p>}
                </div>
              </div>
            </div>
          )
        },
      },
      {
        Header: 'Date',
        accessor: 'created_at',
        sortDescFirst: true,
      },
      {
        Header: 'Images',
        accessor: 'images',
        disableSortBy: true,
      },
      {
        Header: 'Initial Assessment',
        accessor: (c: Cases_cases) => c,
        sortDescFirst: true,
        sortType: (a: { original: Cases_cases }, b: { original: Cases_cases }) => {
          return getMaxSeverity(a.original, conditions, aiMode) - getMaxSeverity(b.original, conditions, aiMode)
        },
      },
      {
        Header: 'Consultation',
        accessor: 'consultation_status',
        disableSortBy: true,
      },
    ],
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      // @ts-ignore
      columns,
      data,
      defaultColumn,
      viewConsultationReport,
      disableSortBy: !props.sortEnabled,
      autoResetSortBy: false,
      initialState: {
        // @ts-ignore
        sortBy: [
          {
            id: 'created_at',
            desc: true,
          },
        ],
      },
    },
    useSortBy
  )

  const colSpan = (data: any) => (isColumn(data, 'Images') || isColumn(data, 'Consultation') ? 2 : 1)

  return (
    <div>
      {switchPatientCase && (
        <SwitchPatientModal
          case={switchPatientCase}
          setSwitchPatientCase={setSwitchPatientCase}
          handleMoveImages={() => {
            setSwitchPatientCase(undefined)
            props.fetchCases()
          }}
        />
      )}
      <Table cellSpacing={0} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup: any, idx: number) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => {
                const isImages = column.Header === 'Images'
                const isConsultation = column.Header === 'Consultation'

                return (
                  <HeaderCell
                    className={isColumn(column, 'Images') ? 'hide-mobile' : ''}
                    key={idx}
                    {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                    colSpan={colSpan(column)}
                  >
                    <div className={`d-flex align-items-center ${isConsultation ? 'justify-content-center' : ''}`}>
                      <Header>{column.render('Header')}</Header>

                      <TableSort column={column} sortEnabled={props.sortEnabled} />

                      {isImages && isUser(role) && <img role="button" onClick={props.uploadDicom} className="ml-1 icon-s" src={add} />}
                    </div>
                  </HeaderCell>
                )
              })}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.length ? (
            rows.map((row: any, idx: number) => {
              prepareRow(row)

              const isHovering = hoveredRow === idx
              const previousRow = idx > 0 ? rows[idx - 1] : undefined
              const samePatient = previousRow?.original.patient.id === row.original.patient.id
              const currentCase: Cases_cases = row.original
              const previousCase: Cases_cases | undefined = samePatient ? previousRow?.original : undefined

              return [
                samePatient && previousCase && <SamePatientLink case={currentCase} otherCase={previousCase} />,
                <Row
                  onMouseLeave={() => setHoveredRow(undefined)}
                  onMouseOver={() => setHoveredRow(idx)}
                  key={idx}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell: any, idx2: number) => {
                    const _case: Cases_cases = cell.row.original
                    const consultation = first(_case.consultations)

                    const possibleIndications = gpFilteredPredictions(_case, conditions, aiMode).sort()
                    const parsedPredictions = (consultation?.receiving_vet_notes_parsed_predictions || []) as ParsedPrediction[]
                    const [possibleIndicationsConfirmed, possibleIndicationsNotConfirmed] = partition(
                      gpFilteredPredictions(_case, conditions, aiMode).sort(),
                      (c) =>
                        consultation?.predictions.some((p) => p.condition?.display_name === c) ||
                        parsedPredictions.some((p) => p.condition === c && p.likelihood !== 'not present')
                    )
                    const risk = getRisk(_case, conditions, aiMode)
                    const isXray = isXrayCase(_case)
                    const hasAi = !isExotic(_case.patient.species) && isXray && !getIsEquipmentFromCase(_case)

                    const imageCount = _case.medical_images.length
                    const aiCompleteImageCount = _case.medical_images.filter((m) => imageIsAiComplete(m)).length
                    const aiComplete = aiCompleteImageCount === imageCount
                    const showScanner = isDemoOrg(user) ? isOddMinute && _case.id === 77492 : !aiComplete && hasAi

                    if (cell.column.Header === 'Initial Assessment') {
                      if (!hasAi) {
                        return <Cell key={idx2} />
                      }

                      const hideAiResults = showScanner || _case.medical_images.length === 0

                      return (
                        <Cell
                          className="position-relative"
                          key={idx2}
                          onClick={() => {
                            if (!user?.organization.enterprise.ai_enabled) return

                            if (blurAiFindings && stripeId) {
                              dispatch(createCustomerPortalAction(stripeId))
                            } else {
                              props.handleClickedAiAssessment(_case.id)
                            }
                          }}
                        >
                          <div
                            className={`
                            position-relative d-flex flex-column align-items-start
                            ${!hideAiResults && user?.organization.enterprise.ai_enabled ? 'hover-border pointer' : 'pe-none'}
                          `}
                            style={{ filter: blurAiFindings ? 'blur(4px)' : '' }}
                          >
                            {hideAiResults ? (
                              <p style={{ padding: '0 5px' }} className="text-xs text--primary bold m-0">
                                {aiCompleteImageCount} of {pluralize('image', imageCount)} complete
                              </p>
                            ) : (
                              <Badge className={`min-width-100px ${risk.toLowerCase()}-risk`}>{risk} Risk</Badge>
                            )}

                            {possibleIndications.length > 0 && !hideAiResults && (
                              <div className="text-xs mb-0">
                                {possibleIndicationsConfirmed.length > 0 && (
                                  <div className="mt-1">
                                    <p className="mb-0 semibold">Specialist confirmed:</p>
                                    {possibleIndicationsConfirmed.map((condition, idx) => (
                                      <div key={idx} className="d-flex align-items-center gap-5px">
                                        <p className="m-0">{condition}</p>
                                      </div>
                                    ))}
                                  </div>
                                )}

                                {possibleIndicationsNotConfirmed.length > 0 && (
                                  <div className="mt-1">
                                    <p className="mb-0 semibold">Possible indications:</p>
                                    {possibleIndicationsNotConfirmed.map((condition, idx) => {
                                      const displaySiren = isErMode && CONFIG.ER_MODE_CONDITIONS.includes(condition)
                                      return (
                                        <div key={idx} className="d-flex align-items-center gap-5px">
                                          {displaySiren && <p className="mb-0 siren">🚨</p>}
                                          <p className="m-0 single-line">{condition}</p>
                                        </div>
                                      )
                                    })}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>

                          {isHovering && blurAiFindings && isRadimalEnterprise && (
                            <p className="position-absolute m-0 text-center top-0 left-0 w-100 h-100 d-flex flex-column align-items-center justify-content-center text-s underline semibold pointer">
                              Upgrade to AI Plan
                            </p>
                          )}
                        </Cell>
                      )
                    } else if (cell.column.id === 'images') {
                      return (
                        <Cell colSpan={2} className="hide-mobile text-center" key={idx2}>
                          <div
                            style={{ zIndex: 10 }}
                            className={`position-relative width-fit-content ${showScanner ? 'p-2 m-2' : 'showScanner'}`}
                          >
                            {_case.dicom_server_study_instance_uid && (
                              <div
                                className={`pointer ${showScanner ? 'scanner' : 'hover-border'}`}
                                onClick={() => {
                                  trackEvent('opened-viewer', { patient: _case.patient.display_name })
                                  const event = {
                                    organization_id: user?.organization.id,
                                    organization_event_type_id: OrganizationEvent.OpenedViewer,
                                    case_id: _case.id,
                                  }
                                  dispatch(insertOrganizationEventAction(accessToken, event))
                                  window.open(ohifStudyUrl(_case.dicom_server_study_instance_uid, _case.dicom_source), '_blank')
                                }}
                              >
                                {showScanner && <div className="scanner-overlay" />}

                                {showScanner && (
                                  <div className="scanner-border">
                                    <p className="scanner-text position-absolute text-primary ls-sm text-xxxs ls-sm bold text-uppercase m-0 w-100 left-0 right-0">
                                      scanning
                                    </p>
                                    <em></em>
                                  </div>
                                )}

                                <div className="d-flex p-2 flex-wrap gap-10px">
                                  {_case.medical_images.slice(0, MAX_IMAGES_PER_ROW).map((m, idx) => {
                                    const src = presignedCaseImageUrls.find((p) => m.aws_s3_url && p.includes(m.aws_s3_url))
                                    if (!src) return null

                                    return <MedicalImageThumbnail isNonXray={!isXray} src={src} key={idx} />
                                  })}
                                </div>
                              </div>
                            )}

                            {isXray && _case.medical_images.length > MAX_IMAGES_PER_ROW && (
                              <p className="text-xs text--gray7 w-100 m-0 text-left pl-3">
                                {pluralize('image', _case.medical_images.length)} ({MAX_IMAGES_PER_ROW} shown)
                              </p>
                            )}

                            {isUltrasoundCase(_case) && !isEmpty(_case.additional_data) && (
                              <p className="text-xs text--gray7 w-100 m-0 text-left pl-3">{imageAndVideoCountsForUltrasound(_case)}</p>
                            )}
                          </div>
                        </Cell>
                      )
                    } else if (cell.column.id === 'consultation_status') {
                      return (
                        <ConsultationCell
                          _case={_case}
                          fetchCases={props.fetchCases}
                          key={idx2}
                          shouldPopBillingModal={props.shouldPopBillingModal}
                          viewConsultationReport={viewConsultationReport}
                        />
                      )
                    } else if (cell.column.id === 'created_at') {
                      return (
                        <Cell key={idx2} {...cell.getCellProps()}>
                          <div className="position-relative">
                            <p className="m-0 text-m">{parseDate(_case.created_at)}</p>
                            <p style={{ top: '95%' }} className="m-0 text-muted text-xxs position-absolute">
                              {moment(_case.created_at).fromNow()}
                            </p>
                          </div>
                        </Cell>
                      )
                    } else {
                      return (
                        <Cell key={idx2} {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </Cell>
                      )
                    }
                  })}
                </Row>,
              ]
            })
          ) : (
            <NoResults columns={columns?.length} message="No cases." />
          )}
        </tbody>
      </Table>
    </div>
  )
}
