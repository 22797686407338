import React from 'react'

import MiniStep from '../MiniStep'

export default (
  <div>
    <h6 className="bold mt-4">Add Radimal Server</h6>
    <MiniStep
      idx={1}
      children={
        <p className="m-0">
          Login as an "admin" or "service" account
        </p>  
      }
    />
    <MiniStep
      idx={2}
      children={
        <p className="m-0">
          On the right hand side of screen, click 'Setting' and then from the left side of the screen, click 'DICOM', then 'Storage'
        </p>  
      }
    />

    <MiniStep
      idx={3}
      children={
        <div>
          <p className="m-0">Enter the following exactly:</p>
          <ul className="m-0">
            <li>Name - Radimal</li>
            <li>AE Title - RADIMAL</li>
            <li>IP Address - 34.192.244.223</li>
            <li>Port - 4242</li>
            <li>Type - All</li>
          </ul>
          Click 'Update' to add it to the list.
        </div>
      }
    />

    <MiniStep
      idx={4}
      children={
        <div>
          If you use "Multi-Send", scroll down and add the "Radimal" server to your Multi-Send
        </div>
      }
    />
    <MiniStep
      idx={5}
      children={
        <div>
          Send a test case to the "Radimal" server you created using either "Send" or "Multi-Send"
        </div>
      }
    />

  </div>
)
