import styled from 'styled-components'
import React from 'react'

import { Step } from '.'
import { palette } from '../../lib/cssHelpers'

// @ts-ignore
import arrowGray from '../../lib/images/arrow-gray.png'
// @ts-ignore
import arrowBlue from '../../lib/images/arrow-blue.png'
// @ts-ignore
import checkmark from '../../lib/images/checkmark-white.png'

interface StepComponentProps {
  step: Step
  idx: number
  selected?: Step
  setStep: (step: Step) => void
  children: any
  completed: boolean
}

export default function StepComponent(props: StepComponentProps) {
  const isSelected = props.step === props.selected

  return (
    <StepBox isSelected={isSelected} className="p-3 m-2 rounded transition-m">
      <div onClick={() => props.setStep(props.step)} className="d-flex align-items-center justify-content-between w-100">
        <div className="d-flex align-items-center">
          {!props.completed ? (
            <div className="border border-dark rounded rounded-circle p-1 icon-m d-flex align-items-center justify-content-center mr-2">
              <p className="text-s bold m-0">{props.idx}</p>
            </div>
          ) : (
            <div className="bg-success rounded rounded-circle p-1 icon-m d-flex align-items-center justify-content-center mr-2">
              <img className="icon-xs" src={checkmark} />
            </div>
          )}

          <h6 className="m-0">{props.step}</h6>
        </div>

        <img
          style={{ transform: `rotate(${isSelected ? 90 : 0}deg)` }}
          className="icon-m transition-m"
          src={isSelected ? arrowBlue : arrowGray}
        />
      </div>

      <div style={{ marginLeft: '45px', maxHeight: isSelected ? '9999px' : 0, overflow: 'hidden', transition: '1s ease max-height' }}>
        {props.children}
      </div>
    </StepBox>
  )
}

interface StepBoxProps {
  isSelected: boolean
}

const StepBox = styled.div`
  cursor: pointer;
  border: 1px solid ${(p: StepBoxProps) => (p.isSelected ? palette.secondary : 'transparent')};
  opacity: ${(p: StepBoxProps) => (p.isSelected ? 1 : 0.8)};
  box-shadow: ${(p: StepBoxProps) => p.isSelected && '0 4px 14px 0 rgb(0 0 0 / 10%)'};
  &:hover {
    border: 1px solid ${palette.secondary};
    opacity: 1;
    box-shadow: 0 4px 14px 0 rgb(0 0 0 / 10%);
  }
`
