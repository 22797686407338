import React, { useMemo } from 'react'
import isNumber from 'lodash/isNumber'
import moment from 'moment'
import { Badge, Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { navigate } from 'gatsby'
import { useDispatch, useSelector } from 'react-redux'
import { useTable, useSortBy } from 'react-table'

import MedicalImageThumbnail from '../common/MedicalImageThumbnail'
import PriceCalculator from '../../utils/PriceCalculator'
import { AiMode } from '../../interfaces/AiMode'
import { Consultations_consultations, Consultations_consultations_case } from '../../hasura/graphQlQueries/types/Consultations'
import { Table, Header, HeaderCell, Cell, TextCell, NoResults, Row, isColumn } from '../../components/common/Table'
import { TableSort } from '../common/TableSort'
import { consultationsSelector, ConsultationsState, deleteConsultationAction } from '../../hasura/slices/consultations'
import { emojiFor, isExotic, speciesFor } from '../../lib/helpers'
import { getGpPredictions, getMaxSeverity, getRisk, gpFilteredPredictions, Risk } from '../../lib/aiHelpers'
import { isXrayCase, getConsultationTypeForId, ConsultationTypeData } from '../../lib/modalityHelpers'
import { statTypeDescription } from '../../lib/pricingHelpers'
import { updateVetStatusAction, usersSelector, UsersState } from '../../hasura/slices/users'
import { specialistPaymentsSelector, SpecialistPaymentsState } from '../../hasura/slices/specialistPayments'

// @ts-ignore
import dots from '../../lib/images/dots.svg'

const defaultColumn = {
  Cell: TextCell,
}

const MAX_IMAGES_PER_ROW = 8

interface Props {
  data: Consultations_consultations[]
  handleClickedAiAssessment: (id: number) => void
  dark: boolean
}

export default function ClaimTable(props: Props) {
  const dispatch = useDispatch()

  const { data } = props

  const { vetStatus, user, accessToken, defaultTurnaroundTime }: UsersState = useSelector(usersSelector)
  const { conditions, presignedCaseImageUrls }: ConsultationsState = useSelector(consultationsSelector)
  const { specialistPayments }: SpecialistPaymentsState = useSelector(specialistPaymentsSelector)

  const statOn = vetStatus?.stat_plus_vet_ids.includes(user?.id || '')
  const [selectedRisk, setSelectedRisk] = React.useState<Risk | undefined>()
  const [hoveringRisk, setHoveringRisk] = React.useState<Risk | undefined>()

  const memoizedData = useMemo(() => {
    return data.filter((c) => !selectedRisk || getRisk(c.case, conditions, AiMode.Default) === selectedRisk)
  }, [data, selectedRisk])

  const handleDeleteConsultation = async (id: number) => {
    await dispatch(deleteConsultationAction(accessToken, id))
    window.location.reload()
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Description',
        disableSortBy: true,
        accessor: (c: Consultations_consultations) => c,
        Cell: (c: { value: Consultations_consultations }) => {
          const requestedAt = c.value.upgraded_at || c.value.created_at
          const duration = moment.duration(moment().diff(moment(requestedAt)))
          const hours = Math.floor(duration.asHours())
          const minutes = Math.floor(duration.asMinutes() % 60)
          const description = statTypeDescription(defaultTurnaroundTime, c.value.stat_type)
          return (
            <div className="pointer" onClick={() => navigate(`/consultations?i=${c.value.id}&s=claim-list`)}>
              <p className="text-m m-0 d-flex align-items-center">
                <span className="mr-2" style={{ fontSize: '1.8em' }}>
                  {emojiFor(speciesFor(c.value.case.patient.species))}
                </span>
                {c.value.case.patient.display_name}
              </p>

              <p className="m-0 text-xs">
                Requested {hours > 0 ? `${hours}hr ` : ''}
                {minutes}min ago
                <span className="hide-mobile ml-1">by {c.value.case.dicom_server?.organization?.display_name}</span>
                <span className="text--gray5 bold ml-1">| {c.value.case.dicom_server?.organization?.enterprise.short_name}</span>
                {isNumber(c.value.stat_type?.hours) && (
                  <span className="d-block bold text--success">
                    {description.includes('Immediate') ? '🚨 ' : ''}
                    {description}
                  </span>
                )}
              </p>
            </div>
          )
        },
      },
      {
        Header: 'Modality',
        disableSortBy: true,
        accessor: (c: Consultations_consultations) => getConsultationTypeForId(c.consultation_type_id),
        Cell: (c: { value: ConsultationTypeData }) => (
          <p style={{ color: c.value.color }} className="text-m m-0 bold">
            {c.value.consultationTypeShort}
          </p>
        ),
      },
      {
        Header: 'History',
        disableSortBy: true,
        accessor: (c: Consultations_consultations) => c,
        Cell: (c: { value: Consultations_consultations }) => {
          const text = c.value.sending_vet_notes
            ? (c.value.sending_vet_notes.length || 0) > 200
              ? `${c.value.sending_vet_notes?.slice(0, 200)}...`
              : c.value.sending_vet_notes
            : ''
          return <p className="m-0 text-s">{text.replace(/\d+ image\(s\) - /, '')}</p>
        },
      },
      {
        Header: 'Images',
        accessor: (c: Consultations_consultations) => c.case,
        disableSortBy: true,
        Cell: (c: { value: Consultations_consultations_case }) => {
          if (!isXrayCase(c.value)) return null

          const images = c.value.medical_images

          return (
            <div>
              <div className="d-flex flex-wrap gap-10px">
                {images.slice(0, MAX_IMAGES_PER_ROW).map((m, idx) => {
                  const src = presignedCaseImageUrls.find((p) => m.aws_s3_url && p.includes(m.aws_s3_url))
                  if (!src) return null

                  return <MedicalImageThumbnail src={src} key={idx} />
                })}
              </div>
              {images.length > MAX_IMAGES_PER_ROW && <p className="m-0 text-xs text--gray7 pt-1">and more ...</p>}
            </div>
          )
        },
      },
      {
        Header: 'Initial Assessment',
        accessor: (c: Consultations_consultations) => c,
        sortDescFirst: true,
        sortType: (a: { original: Consultations_consultations }, b: { original: Consultations_consultations }) => {
          return (
            getMaxSeverity(a.original.case, conditions, AiMode.Default) - getMaxSeverity(b.original.case, conditions, AiMode.Default)
          )
        },
        Cell: (c: { value: Consultations_consultations }) => {
          const _case = c.value.case
          const possibleIndications = gpFilteredPredictions(_case, conditions, AiMode.Default)
          const gpPredictions = getGpPredictions(_case).map((g) => g.condition?.display_name)
          const risk = getRisk(_case, conditions, AiMode.Default)

          return !isExotic(_case.patient.species) ? (
            <Cell className="position-relative">
              <Badge className={`min-width-100px ${risk.toLowerCase()}-risk`}>{risk} Risk</Badge>

              {possibleIndications.length > 0 && (
                <p className="text-xs mb-0 mt-1">
                  <span className="mr-1 semibold">Possible indications:</span>{' '}
                  {possibleIndications
                    .sort()
                    .map((s) => (gpPredictions.includes(s) ? `${s} (GP)` : s))
                    .join(', ')}
                </p>
              )}
            </Cell>
          ) : (
            <Cell />
          )
        },
      },
      {
        Header: 'Claim',
        disableSortBy: true,
        accessor: 'claim',
      },
    ],
    [presignedCaseImageUrls]
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      // @ts-ignore
      columns,
      autoResetSortBy: false,
      data: memoizedData,
      defaultColumn,
      initialState: {},
    },
    useSortBy
  )

  const textAlign = (data: any) => (isColumn(data, 'Claim') ? 'text-right' : 'text-left')
  const colSpan = (data: any) => (isColumn(data, 'Images') ? 3 : isColumn(data, 'Modality') ? 1 : 2)

  return (
    <Table className="min-height-200px" cellSpacing={0} {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup: any, idx: number) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => {
              const isClaim = column.Header === 'Claim'
              const isInitialAssessment = column.Header === 'Initial Assessment'
              return (
                <HeaderCell
                  className={`
                    ${isColumn(column, 'History') || isColumn(column, 'Images') ? 'hide-mobile' : ''} 
                    ${textAlign(column)}
                    align-top
                  `}
                  key={idx}
                  {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                  colSpan={colSpan(column)}
                >
                  <div>
                    {!isClaim && <Header dark={props.dark}>{column.render('Header')}</Header>}
                    <TableSort column={column} sortEnabled />
                  </div>

                  {isInitialAssessment && (
                    <div className="d-flex width-100px semibold text-xs text--gray2 mt-1">
                      {[Risk.Low, Risk.Medium, Risk.High].map((r) => {
                        const isSelected = selectedRisk === r
                        const highlight = (hoveringRisk || selectedRisk) === r
                        return (
                          <p
                            onClick={(e) => {
                              e.stopPropagation()
                              setSelectedRisk(isSelected ? undefined : r)
                            }}
                            className={`
                                pointer m-0 text-center flex-even hover-text--primary transition-f px-1 border-bottom
                                ${highlight ? 'text--primary border-primary' : 'border-white'}
                              `}
                            key={r}
                            onMouseEnter={() => setHoveringRisk(r)}
                            onMouseLeave={() => setHoveringRisk(undefined)}
                          >
                            {r}
                          </p>
                        )
                      })}
                    </div>
                  )}
                </HeaderCell>
              )
            })}
          </tr>
        ))}
      </thead>

      <tbody {...getTableBodyProps()}>
        {rows.length ? (
          rows.map((row: any, idx: number) => {
            prepareRow(row)

            return (
              <Row key={idx} {...row.getRowProps()}>
                {row.cells.map((cell: any, idx2: number) => {
                  const consultation: Consultations_consultations = cell.row.original

                  if (cell.column.id === 'claim') {
                    const claimed = vetStatus?.my_locked_consultation_ids.includes(consultation!.id)
                    return (
                      <Cell key={idx2} className="text-right" colSpan={2}>
                        <div className="d-flex align-items-center justify-content-end">
                          <p className={`hide-mobile text-l m-0 mr-3 semibold ${statOn ? 'text--success' : 'transition-m'}`}>
                            $
                            {
                              PriceCalculator.receivingVetPayAmount(specialistPayments, consultation, vetStatus, user)
                                .amountWithBonuses
                            }
                          </p>

                          <div className="d-flex align-items-center">
                            <div className="position-relative">
                              <Button
                                color={claimed ? 'border--danger bg--danger' : 'primary'}
                                className="text--white min-width-100px"
                                onClick={() => {
                                  if (!user) return

                                  const value2 = claimed ? 'unlock' : 'lock'
                                  if (
                                    value2 === 'unlock' &&
                                    !window.confirm(
                                      `Are you sure you want to release this ${consultation.case.patient.display_name}'s case?`
                                    )
                                  ) {
                                    return
                                  }

                                  dispatch(updateVetStatusAction(user, 'consultation', String(consultation!.id), value2))
                                }}
                                disabled={!vetStatus}
                              >
                                {claimed ? 'Claimed' : 'Claim'}
                              </Button>

                              {claimed && (
                                <p className="position-absolute text-center text-xxs text--gray7 left-0 right-0">Click to release</p>
                              )}
                            </div>

                            <UncontrolledDropdown className="dropdown-menu-dots" onClick={(e: any) => e.stopPropagation()}>
                              <DropdownToggle caret>
                                <img className="icon-s ml-2 mb-1" src={dots} />
                              </DropdownToggle>

                              <DropdownMenu right>
                                <DropdownItem header>
                                  <p className="m-0 text-s text--gray8">More actions</p>
                                </DropdownItem>

                                <DropdownItem text>
                                  <p
                                    onClick={() => {
                                      if (!window.confirm(`Are you sure you want to delete this case? This cannot be undone.`)) return

                                      handleDeleteConsultation(consultation.id)
                                    }}
                                    className="m-0 text-s regular pointer"
                                  >
                                    Delete
                                  </p>
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        </div>
                      </Cell>
                    )
                  }

                  return (
                    <Cell
                      className={`${isColumn(cell, 'History') || isColumn(cell, 'Images') ? 'hide-mobile' : ''}`}
                      colSpan={colSpan(cell)}
                      key={idx2}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </Cell>
                  )
                })}
              </Row>
            )
          })
        ) : (
          <NoResults columns={columns?.length} message="No cases." />
        )}
      </tbody>
    </Table>
  )
}
