import React, { useState } from 'react'
import { Tooltip } from 'reactstrap'

import { Cases_cases } from '../../hasura/graphQlQueries/types/Cases'

// @ts-ignore
import linkIcon from '../../lib/images/link.svg'

interface Props {
  case: Cases_cases
  otherCase: Cases_cases
}

export default function SamePatientLink(props: Props) {
  const [displayTooltip, setDisplayTooltip] = useState(false)

  return (
    <tr
      onMouseLeave={() => setDisplayTooltip(false)}
      onMouseOver={() => setDisplayTooltip(true)}
      className="w-100 text-center position-relative"
    >
      <td colSpan={7}>
        <img
          id={`case-${props.case.id}`}
          style={{ top: '-13px' }}
          src={linkIcon}
          className="icon-s position-absolute bg--white border hover-border--newblue transition-s border--muted rounded-circle p-1"
        />
      </td>

      {displayTooltip && (
        <Tooltip
          arrowClassName="tooltip-arrow-light"
          fade={false}
          innerClassName="tooltip-inner-light dark-bg max-width-350px"
          isOpen
          placement="bottom"
          target={`case-${props.case.id}`}
        >
          <p className="text-m m-0 p-1 regular">
            {props.case.patient.display_name} has multiple cases. To combine images, click <span className="bold">Request</span> next
            to either case.
          </p>
        </Tooltip>
      )}
    </tr>
  )
}
