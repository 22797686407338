import React from 'react'

import MiniStep from '../MiniStep'

export default (
  <div>
    <h6 className="bold mt-4">Add Radimal Server</h6>
    <MiniStep
      idx={1}
      children={
        <p className="m-0">
          From the top left ‘edit’ dropdown (File, Edit, Utilities) open 'Properties' then the 'Remote Devices'
        </p>  
      }
    />

    <MiniStep
      idx={2}
      children={
        <div>
          <p className="m-0">Enter the following exactly:</p>
          <ul className="m-0">
            <li>Server Name - Radimal</li>
            <li>AE Title - RADIMAL</li>
            <li>IP Address - 34.192.244.223</li>
            <li>Port - 4242</li>
            <li>Type - All</li>
          </ul>
          Click 'Add' to add it to the list
        </div>
      }
    />

    <MiniStep
      idx={3}
      children={
        <div>
          From the list, click the 'Radimal' server and then click 'Verify', then close
        </div>
      }
    />


    <h6 className="bold mt-4">Enable Autosend</h6>
    <MiniStep
      idx={1}
      children={
        <p className="m-0">
          From the top left ‘Edit’ dropdown (File, Edit, Utilities) open 'Properties' then the 'Auto Routing'
        </p>  
      }
    />
    <MiniStep
      idx={2}
      children={
        <p className="m-0">
          Click 'Add New Route' to add it to the list
        </p>  
      }
    />

    <MiniStep
      idx={3}
      children={
        <p className="m-0">
          Click to highlight, in the settings below rename it to "Radimal", set the target device type to DICOM, and and in device names select 'Radimal', click 'OK'
        </p>  
      }
    />
    <h6 className="bold mt-4">Send a test case</h6>
    <div>Highlight a study, click the 'Send' tab, select 'Radimal' destination, click 'Send'</div>
  </div>
)
