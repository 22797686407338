import React, { useMemo } from 'react'
import moment from 'moment'
import { Badge, Button } from 'reactstrap'
import { navigate } from 'gatsby'
import { useTable } from 'react-table'

import CONFIG from '../../config'
import { Table, Header, HeaderCell, Cell, TextCell, Row, isColumn } from '../../components/common/Table'
import { VetDataTask, claimCaseQuestionAction, instanceOfCaseQuestion } from '../../hasura/slices/cases'
import { useDispatch, useSelector } from 'react-redux'
import { UsersState, slackMessageAction, usersSelector } from '../../hasura/slices/users'
import { claimTrainingIterationAction } from '../../hasura/slices/medical-images'
import { TrainingIterations_training_iterations } from '../../hasura/graphQlQueries/types/TrainingIterations'

const defaultColumn = {
  Cell: TextCell,
}

interface Props {
  data: VetDataTask[]
  dark: boolean
}

const MAX_LENGTH = 120

const PRIORITY_HASH = {
  1: 'Low Priority',
  2: 'High Priority',
}

export default function TrainingIterationsTable(props: Props) {
  const { data } = props

  const dispatch = useDispatch()

  const { accessToken, user }: UsersState = useSelector(usersSelector)

  const hasHighPriorityTrainingSet = data
    .filter((d) => !instanceOfCaseQuestion(d))
    // @ts-ignore
    .some((t: TrainingIterations_training_iterations) => t.condition[`active_learning_${t.species}_priority`] === 2)

  const columns = useMemo(
    () => [
      {
        Header: 'Task',
        accessor: (t: VetDataTask) => t,
        Cell: (c: { value: VetDataTask }) => {
          let text: string
          let priority: string | undefined
          if (instanceOfCaseQuestion(c.value)) {
            text = c.value.case_questions_denormalized[0].text
          } else {
            text = `Label ${c.value.condition.display_name}`
            // @ts-ignore
            priority = PRIORITY_HASH[c.value.condition[`active_learning_${c.value.species}_priority`]]
          }
          text = `${text.slice(0, MAX_LENGTH)}${text.length > MAX_LENGTH ? '...' : ''}`
          return (
            <p className="text-s m-0 d-flex align-items-center justify-content-between">
              {text}
              {priority && <Badge color={priority.includes('High') ? 'primary' : 'secondary'}>{priority}</Badge>}
            </p>
          )
        },
      },
      {
        Header: 'Created',
        accessor: (t: VetDataTask) => moment(t.created_at).fromNow(),
        Cell: (c: any) => <p className="text-s m-0 d-flex align-items-center">{c.value}</p>,
      },
      {
        Header: 'Progress',
        accessor: (t: VetDataTask) => t,
        Cell: (c: { value: VetDataTask }) => (
          <p className="text-s m-0 d-flex align-items-center">
            {instanceOfCaseQuestion(c.value)
              ? `${c.value.case_responses_denormalized.length} of ${c.value.case_questions_denormalized.length}`
              : `${c.value.predictions_normalizeds.length} of ${c.value.medical_image_ids_denormalized.split(',').length}`}
          </p>
        ),
      },
      {
        Header: 'Start',
        accessor: (t: VetDataTask) => t,
        Cell: (c: { value: VetDataTask }) => {
          const isLowPriorityTrainingSet =
            // @ts-ignore
            !instanceOfCaseQuestion(c.value) && c.value.condition[`active_learning_${c.value.species}_priority`] === 1
          const hide = hasHighPriorityTrainingSet && isLowPriorityTrainingSet
          return (
            <div className={`text-m m-0 d-flex align-items-center justify-content-end ${hide ? 'hidden' : ''}`}>
              <p className="hide-mobile text-l m-0 mr-3 semibold">
                $
                {instanceOfCaseQuestion(c.value)
                  ? CONFIG.CASE_QUESTION_PER_CASE_PAY_AMOUNT * c.value.case_questions_denormalized.length
                  : c.value.pay_amount}
              </p>

              <Button
                onClick={() => {
                  if (instanceOfCaseQuestion(c.value)) {
                    dispatch(slackMessageAction('ai-report', `${user?.display_name} claimed case question.`))
                    dispatch(claimCaseQuestionAction(accessToken, c.value.id, user?.id!))
                  } else {
                    if (!c.value.vet_id) {
                      dispatch(claimTrainingIterationAction(accessToken, c.value.id, user?.id!))
                    } else {
                      navigate(`/tagging/binary/?i=${c.value.id}`)
                    }
                  }
                }}
                color="primary"
                className="text--white min-width-100px"
              >
                {instanceOfCaseQuestion(c.value) && !c.value.vet_id ? 'Claim' : 'Start'}
              </Button>
            </div>
          )
        },
      },
    ],
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    // @ts-ignore
    columns,
    data,
    defaultColumn,
  })

  return (
    <Table cellSpacing={0} {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup: any, idx: number) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => {
              return (
                <HeaderCell className={isColumn(column, 'Created') ? 'hide-mobile' : ''} key={idx} {...column.getHeaderProps()}>
                  {column.Header !== 'Start' && <Header dark={props.dark}>{column.render('Header')}</Header>}
                </HeaderCell>
              )
            })}
          </tr>
        ))}
      </thead>

      <tbody {...getTableBodyProps()}>
        {rows.map((row: any, idx: number) => {
          prepareRow(row)

          return (
            <Row key={idx} {...row.getRowProps()}>
              {row.cells.map((cell: any, idx2: number) => {
                return (
                  <Cell className={isColumn(cell, 'Created') ? 'hide-mobile' : ''} key={idx2} {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </Cell>
                )
              })}
            </Row>
          )
        })}
      </tbody>
    </Table>
  )
}
