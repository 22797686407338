import React from 'react'

// @ts-ignore
import reorder from '../../lib/images/reorder.png'
// @ts-ignore
import arrow from '../../lib/images/arrow-down.png'

export const TableSort = (props: { column: any; sortEnabled: boolean }) => {
  return props.column.disableSortBy || !props.sortEnabled ? null : props.column.isSorted ? (
    <img
      style={{ opacity: 0.75, marginLeft: '2px', transform: props.column.isSortedDesc ? '' : 'rotate(180deg)' }}
      className="icon-xs"
      src={arrow}
    />
  ) : (
    <img style={{ opacity: 0.5 }} className="icon-s" src={reorder} />
  )
}
