import * as Yup from 'yup'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Button } from 'reactstrap'
import { Formik, Form } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import Chapter from './chapter'
import DicomSetupSteps from './dicomSetupSteps'
import StepComponent from './step'
import { FormikInput } from '../common/Input'
import { FormikRadioButton } from '../common/FormikRadioButton'
import { fetchUserAction, updateUserDisplayNameAction, usersSelector, UsersState } from '../../hasura/slices/users'
import { phoneRegExp, trackHotjarEvent } from '../../lib/helpers'
import { updateOrganizationAction } from '../../hasura/slices/organizations'

// @ts-ignore
import catDog from '../../lib/images/cat-dog.png'

export enum Step {
  Intro = 'Introduction',
  XraySetup = 'Setup your x-ray machine',
}

export default function Setup() {
  const dispatch = useDispatch()

  const { accessToken, user }: UsersState = useSelector(usersSelector)

  const [step, setStep] = useState<Step | undefined>(Step.Intro)
  const [completed, setCompleted] = useState<Step[]>([])

  const hasDefaultName = user?.organization.display_name === 'My Hospital'

  /*
    Effects
  */

  useEffect(() => {
    trackHotjarEvent('started_onboarding')
  }, [])

  useEffect(() => {
    if (user?.organization.display_name === 'My Hospital') return

    setCompleted([Step.Intro])
  }, [user])

  /*
    Methods
  */

  const handleFormSubmit = async (values: any) => {
    setStep(Step.XraySetup)
    await dispatch(
      updateOrganizationAction(
        accessToken,
        user!.organization.id,
        true,
        values['hospital'],
        undefined,
        values['phoneNumber'],
        values['numberOfVets']
      )
    )
    if (values['name']) await dispatch(updateUserDisplayNameAction(accessToken, user!.id, values['name']))
    dispatch(fetchUserAction(accessToken, user!.id, false))
  }

  const handleSetStep = (newStep: Step) => setStep(newStep === step ? undefined : newStep)

  const NUMBER_VETS_OPTIONS = [
    { key: '1', value: '1' },
    { key: '2', value: '2' },
    { key: '3', value: '3' },
    { key: '3 to 5', value: '3 to 5' },
    { key: '6 to 10', value: '6 to 10' },
    { key: 'more than 10', value: 'more than 10' },
  ]

  /*
    Steps
  */

  const introSteps = (
    <div className="p-1">
      <StepComponent
        idx={1}
        selected={step}
        setStep={handleSetStep}
        step={Step.Intro}
        completed={completed.includes(Step.Intro)}
        children={
          <div className="mt-4">
            <div className="d-flex align-items-start gap-20px">
              <div className="flex-even">
                <p className="text-m">
                  Welcome to Radimal, your new <span className="text--primary semibold">AI-powered Teleradiology Platform</span>! We're
                  excited to help you provide better care for your patients.
                  <br />
                  <br />
                  After a bit of setup, you will start receiving AI reports and you will be able to request specialist consultations.
                </p>
              </div>

              <div className="flex-even">
                <img className="mw-100 max-height-150px" src={catDog} />
              </div>
            </div>

            <Formik
              initialValues={{
                hospital: hasDefaultName ? '' : user?.organization.display_name,
                phoneNumber: user?.organization.primary_phone_number || '',
                numberOfVets: user?.organization.number_of_vets,
                name: moment().diff(moment(user?.created_at), 'minutes') < 1 ? '' : user?.display_name,
              }}
              onSubmit={handleFormSubmit}
              validationSchema={Yup.object().shape({
                name: Yup.string().max(100, 'Must be under 100 characters.'),
                hospital: Yup.string().max(100, 'Must be under 100 characters.').required('Cannot be blank.'),
                phoneNumber: Yup.string().matches(phoneRegExp, 'Must be a valid phone number.'),
                numberOfVets: Yup.mixed()
                  .oneOf(NUMBER_VETS_OPTIONS.map((a) => a.value))
                  .required('Cannot be blank.'),
              })}
            >
              {(formik) => (
                <Form style={{ margin: '20px 0' }}>
                  <FormikInput
                    autoFocus
                    label="What is your name?"
                    name="name"
                    placeholder="Full name"
                    type="text"
                    containerClassName="mb-3"
                  />

                  <FormikInput
                    label="What is your hospital name?"
                    name="hospital"
                    placeholder="Hospital name"
                    type="text"
                    containerClassName="mb-3"
                  />

                  <FormikInput
                    containerClassName="mb-3"
                    label="What is your hospital's direct phone number?"
                    subLabel="Give our specialists a number to call for questions regarding your patients."
                    name="phoneNumber"
                    placeholder="(000) 000-0000"
                    type="text"
                  />

                  <FormikRadioButton
                    containerClassName="mb-2"
                    label="How many vets work at your hospital?"
                    name="numberOfVets"
                    options={NUMBER_VETS_OPTIONS}
                  />

                  <Button
                    disabled={formik.isSubmitting || !formik.isValid}
                    type="submit"
                    color="primary"
                    className="mt-3 min-width-200px"
                  >
                    Continue
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        }
      />
    </div>
  )

  return (
    <div>
      <h4 className="bold">Your Quick Start Guide</h4>

      <Chapter progress={completed.includes(Step.Intro) ? 100 : 5} title="Get to know Radimal" minutes={1} children={introSteps} />

      <Chapter
        progress={step === Step.XraySetup ? 50 : 0}
        title="Get ready to receive reports"
        minutes={5}
        children={<DicomSetupSteps completed={completed} step={step} setStep={handleSetStep} />}
      />
    </div>
  )
}
