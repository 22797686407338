import React from 'react'

import MiniStep from '../MiniStep'

export default (
  <div>
    <h6 className="bold mt-4">Add a New Server</h6>

    <MiniStep
      idx={1}
      children={
        <p className="m-0">
          From the home window, click <b>Settings</b> (gear icon)
        </p>
      }
    />

    <MiniStep
      idx={2}
      children={
        <p className="m-0">
          From the left side of the System Settings window, click <b>DICOM Services</b> and then <b>Remote Servers</b>
        </p>
      }
    />

    <MiniStep
      idx={3}
      children={
        <p className="m-0">
          Click <b>Add a New Server</b>
        </p>
      }
    />

    <MiniStep
      idx={4}
      children={
        <div>
          <p className="m-0">Enter the following exactly:</p>
          <ul className="m-0">
            <li>Server Name - Radimal</li>
            <li>AE Title - RADIMAL</li>
            <li>IP Address - 34.192.244.223</li>
            <li>Port - 4242</li>
          </ul>
        </div>
      }
    />

    <MiniStep
      idx={5}
      children={
        <p className="m-0">
          Click <b>Save</b> to close the window
        </p>
      }
    />

    <MiniStep idx={6} children={<p className="m-0">Send a patient case to 'Radimal' as a test</p>} />

    <h6 className="bold mt-4">Configure Auto-Routing</h6>

    <MiniStep
      idx={1}
      children={
        <p className="m-0">
          From the home window, click <b>Settings</b> (gear icon) again
        </p>
      }
    />

    <MiniStep
      idx={2}
      children={
        <p className="m-0">
          From the left side of the System Settings window, click <b>DICOM Services</b> and then <b>Auto-Routing</b>
        </p>
      }
    />

    <MiniStep
      idx={3}
      children={
        <p className="m-0">
          From the upper left, under Auto-Routing mode, select <b>Automatic</b>
        </p>
      }
    />

    <MiniStep
      idx={4}
      children={
        <p className="m-0">
          In the Remote Servers box, select <b>Radimal</b>
        </p>
      }
    />

    <MiniStep
      idx={5}
      children={
        <p className="m-0">
          Click <b>Save</b> and close the window
        </p>
      }
    />
  </div>
)
