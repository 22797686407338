import React from 'react'

import MiniStep from '../MiniStep'

export default (
  <div>
    <MiniStep
      idx={1}
      children={
        <div>
          <p className="m-0">Add us to your x-ray machine:</p>
          <ul className="m-0">
            <li>AE Title - Radimal</li>
            <li>IP Address - 34.192.244.223</li>
            <li>Port - 4242</li>
            <li>Client AE Title - RADIMAL</li>
          </ul>
        </div>
      }
    />

    <MiniStep idx={2} children={<p className="m-0">Make sure auto-sending is enabled</p>} />

    <MiniStep
      idx={3}
      children={
        <p className="m-0">
          Verify the connection - in the same window there should be a "verify" or "ping" button. Highlight the RADIMAL remote server
          and press "ping". If the connection was not successful check the information you entered for typos.
        </p>
      }
    />
  </div>
)
