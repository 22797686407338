import { useSelector } from 'react-redux'

import { UsersState, usersSelector } from '../../hasura/slices/users'
import { trackHotjarEvent } from '../../lib/helpers'
import { useCasesCount } from '../../hooks/useCasesCount'
import { useCompletedConsultationsCount } from '../../hooks/useCompletedConsultationsCount'
import { useMedicalImagePermutationsWithAiCount } from '../../hooks/useMedicalImagePermutationsWithAiCount'
import { useMedicalImagesCount } from '../../hooks/useMedicalImagesCount'
import { useMedicalImagesWithAiCount } from '../../hooks/useMedicalImagesWithAiCount'

interface UserCasesTableSubscriptionsProps {
  refresh: () => void
}

export default (props: UserCasesTableSubscriptionsProps) => {
  const { user }: UsersState = useSelector(usersSelector)

  useCasesCount((_) => {
    trackHotjarEvent('case_created')
    props.refresh()
  }, user!)

  useMedicalImagesCount((_) => {
    props.refresh()
  }, user!)

  useMedicalImagesWithAiCount((_) => {
    props.refresh()
  }, user!)

  useMedicalImagePermutationsWithAiCount((_) => {
    props.refresh()
  }, user!)

  useCompletedConsultationsCount(() => {
    props.refresh()
  }, user!)

  return null
}
