import { useEffect, useState } from 'react'
import gql from 'graphql-tag'
import { useSubscription } from '@apollo/client'

import { User_users } from '../hasura/graphQlQueries/types/User'

// @ts-ignore
import { usePrevious } from './usePrevious'

const MEDICAL_IMAGES_UPDATED = (organization_id: number) => gql`
  subscription OnMedicalImagesWithAiUpdated {
    medical_images_aggregate(where: {ai_predictions_denormalized: {_is_null: false}, aws_s3_url: {_is_null: false}, case: {dicom_server: {organization_id: {_eq: ${organization_id}}}}}) {
      aggregate {
        count
      }
    }
  }
`

export const useMedicalImagesWithAiCount = (medicalImageCreatedCallback: (count: number) => void, user: User_users) => {
  const [medicalImagesCount, setMedicalImagesCount] = useState<number | undefined>(undefined)

  const medicalImagesWithAiSubscription = useSubscription(MEDICAL_IMAGES_UPDATED(user.organization.id))
  const previousMedicalImagePermutationsWithAiCount = usePrevious(medicalImagesCount)

  useEffect(() => {
    const count = medicalImagesWithAiSubscription.data?.medical_images_aggregate?.aggregate?.count

    const medicalImageCreated =
      previousMedicalImagePermutationsWithAiCount !== undefined && count > previousMedicalImagePermutationsWithAiCount!
    if (medicalImageCreated) medicalImageCreatedCallback(count)
    setMedicalImagesCount(count)
  }, [user, medicalImagesWithAiSubscription.data?.medical_images_aggregate?.aggregate?.count])
}
