import React from 'react'

import MiniStep from '../MiniStep'

export default (
  <div>
    <MiniStep
      idx={1}
      children={
        <p className="m-0">
          From the home page, click <b>Settings</b> (gear icon) and then <b>Sending Images</b>
        </p>
      }
    />

    <MiniStep
      idx={2}
      children={
        <p className="m-0">
          Click <b>Add Partner</b>
        </p>
      }
    />

    <MiniStep
      idx={3}
      children={
        <p className="m-0">
          Enter 'Radimal' as the name, then select <b>DICOM</b> as the sharing method
        </p>
      }
    />

    <MiniStep
      idx={4}
      children={
        <div>
          <p className="m-0">Enter the following exactly:</p>
          <ul className="m-0">
            <li>AE Title - RADIMAL</li>
            <li>IP Address - 34.192.244.223</li>
            <li>Port - 4242</li>
          </ul>
        </div>
      }
    />

    <MiniStep
      idx={5}
      children={
        <p className="m-0">
          Click <b>Commit</b> (checkmark icon)
        </p>
      }
    />

    <MiniStep
      idx={6}
      children={
        <p className="m-0">
          Click <b>Close</b>
        </p>
      }
    />

    <MiniStep idx={7} children={<p className="m-0">Send a patient case to 'Radimal' as a test</p>} />
  </div>
)
