import React, { useEffect, useState } from 'react'
import isEqual from 'lodash/isEqual'
import flatten from 'lodash/flatten'
import moment from 'moment'
import { Badge, Button, CustomInput, Spinner, Tooltip } from 'reactstrap'
import { navigate } from 'gatsby'
import { useDispatch, useSelector } from 'react-redux'
import { useIntercom } from 'react-use-intercom'

import AiAssessmentModal from '../ai-assessment/index'
import CONFIG from '../../config'
import ClaimTable from './ClaimTable'
import OverflowSwitch from './OverflowSwitch'
import TrainingIterationsTable from './TrainingIterationsTable'
import { QueryName } from '../../hasura/queryNames'
import { casesSelector, CasesState, fetchCaseQuestionsAction, VetDataTask } from '../../hasura/slices/cases'
import { downloadStudyParamsFor } from '../../lib/combineCompareHelpers'
import { fetchTrainingIterationsAction, medicalImagesSelector, MedicalImagesState } from '../../hasura/slices/medical-images'
import { fetchUserAction, updateUserDarkModeOnAction, usersSelector, UsersState } from '../../hasura/slices/users'
import { orderConsultations, vetNameWithoutQualifications, medicalImageKeysForCase } from '../../lib/helpers'

import {
  consultationsSelector,
  ConsultationsState,
  downloadStudyAction,
  setConsultationsAction,
  fetchImagesAction,
} from '../../hasura/slices/consultations'

// @ts-ignore
import { usePrevious } from '../../hooks/usePrevious'
// @ts-ignore
import folder from '../../lib/images/file-folder.png'
// @ts-ignore
import arrowRightWhite from '../../lib/images/arrow-right-white.svg'
// @ts-ignore
import upArrow from '../../lib/images/boost-arrow.svg'

interface Props {
  dark: boolean
}

const EXCLUDE_AI_TASKS_VET_IDS = ['auth0|61aa41074e04f0007176c15d', 'google-oauth2|100596772846087517637']

export default function ClaimList(props: Props) {
  const dispatch = useDispatch()
  const { trackEvent } = useIntercom()

  const { accessToken, vetStatus, user, isRadimalEnterprise }: UsersState = useSelector(usersSelector)
  const { consultations, incompleteConsultationAddendums, isQuerying }: ConsultationsState = useSelector(consultationsSelector)
  const { caseQuestions }: CasesState = useSelector(casesSelector)
  const { trainingIterations }: MedicalImagesState = useSelector(medicalImagesSelector)

  const [displayAiAssessmentCaseId, setDisplayAiAssessmentCaseId] = useState<number | undefined>()
  const [displayStatBonusTooltip, setDisplayStatBonusTooltip] = useState(false)
  const [displayHolidayBonusTooltip, setDisplayHolidayBonusTooltip] = useState(false)
  const [statBonusActivated, setStatBonusActivated] = useState(false)
  const [holidayBonusActivated, setHolidayBonusActivated] = useState(false)

  const previousVetStatus = usePrevious(vetStatus)

  const isQueryingVetStatus = useSelector(usersSelector).isQuerying[QueryName.FetchVetStatuses]

  useEffect(() => {
    if (!accessToken || !user) return

    dispatch(fetchTrainingIterationsAction(accessToken))
    dispatch(fetchCaseQuestionsAction(accessToken, user.id))
  }, [accessToken, user])

  useEffect(() => {
    if (!vetStatus || isEqual(previousVetStatus, vetStatus)) return

    dispatch(setConsultationsAction(consultations, vetStatus))
  }, [consultations, vetStatus])

  useEffect(() => {
    if (statBonusActivated || !vetStatus?.stat_bonus_activated) return

    setTimeout(() => setStatBonusActivated(true), 500)
  }, [vetStatus, statBonusActivated])

  useEffect(() => {
    if (holidayBonusActivated || !vetStatus?.holiday_bonus_activated) return

    setTimeout(() => setHolidayBonusActivated(true), 500)
  }, [vetStatus, holidayBonusActivated])

  useEffect(() => {
    if (!consultations.length) return

    const keys = flatten(consultations.map((c) => medicalImageKeysForCase(c.case)))
    dispatch(fetchImagesAction(keys))
  }, [consultations])

  /*
    Methods
  */

  const claimedConsultations = consultations.filter((c) => (vetStatus?.my_locked_consultation_ids || []).includes(c.id))

  const updateDarkMode = async () => {
    await dispatch(updateUserDarkModeOnAction(accessToken, user!.id, !user?.dark_mode_on))
    dispatch(fetchUserAction(accessToken, user!.id, false))
  }

  const downloadImages = () => {
    trackEvent('downloaded-studies-images')
    dispatch(downloadStudyAction(accessToken, downloadStudyParamsFor(claimedConsultations)))
  }

  const filteredTrainingIterations = (trainingIterations || []).filter(
    (t) =>
      !t.completed_at &&
      (!t.vet_id || t.vet_id === user?.id) &&
      t.predictions_normalizeds.length < t.medical_image_ids_denormalized.split(',').length &&
      !t.exclude_vet_ids_denormalized?.split(',').includes(user?.id || '')
  )

  const vetTasks: VetDataTask[] = [...filteredTrainingIterations, ...caseQuestions]

  return (
    <div className={props.dark ? 'text-dark-bg' : ''}>
      {displayAiAssessmentCaseId && (
        <AiAssessmentModal handleClose={() => setDisplayAiAssessmentCaseId(undefined)} id={displayAiAssessmentCaseId} />
      )}

      <div className="d-flex justify-content-between align-items-start mb-3">
        <div>
          <div className="d-flex align-items-start mb-2">
            <div className="d-flex align-items-center">
              <h4 className="bold m-0">Cases</h4>

              <div className="d-flex flex-center ml-4 mr-4">
                <p className="text-s m-0">☀️</p>

                <CustomInput
                  id="darkModeOn"
                  checked={user?.dark_mode_on}
                  className="my-0 custom-switch-light ml-2"
                  role="button"
                  type="switch"
                  onChange={updateDarkMode}
                />

                <p className="text-s m-0">🌙</p>
              </div>

              {user?.organization.enterprise.vet_has_overflow_control && <OverflowSwitch />}
            </div>

            <div className="position-relative">
              <div className="d-flex position-absolute align-items-start">
                <Badge
                  id="holiday-bonus-badge"
                  onMouseLeave={() => setDisplayHolidayBonusTooltip(false)}
                  onMouseOver={() => setDisplayHolidayBonusTooltip(true)}
                  className={`
                  ml-4 mb-0 px-2 py-1 text-s semibold border-radius-20px flex-center transition-s t-0 bg--coldsteel
                  ${holidayBonusActivated ? '' : 'pe-none'}
                `}
                  style={{
                    marginTop: holidayBonusActivated ? 0 : '12px',
                    opacity: holidayBonusActivated ? 1 : 0,
                  }}
                >
                  ${CONFIG.HOLIDAY_BONUS} per Case ❄️⛄️🌟
                </Badge>

                <Badge
                  id="stat-bonus-badge"
                  onMouseLeave={() => setDisplayStatBonusTooltip(false)}
                  onMouseOver={() => setDisplayStatBonusTooltip(true)}
                  className={`
                  ml-4 mb-0 px-2 py-1 text-s semibold border-radius-20px flex-center transition-s t-0
                  ${statBonusActivated ? '' : 'pe-none'}
                `}
                  style={{
                    marginTop: statBonusActivated ? 0 : '12px',
                    opacity: statBonusActivated ? 1 : 0,
                  }}
                  color="success"
                >
                  ${CONFIG.STAT_OFF_HOUR_BONUS} for STAT <img className="ml-1 icon-xxxs" src={upArrow} />
                </Badge>
              </div>
            </div>

            {displayHolidayBonusTooltip && (
              <Tooltip
                arrowClassName="tooltip-arrow-light"
                fade={false}
                innerClassName="tooltip-inner-light"
                isOpen
                placement="bottom"
                target="holiday-bonus-badge"
              >
                ${CONFIG.HOLIDAY_BONUS} per case holiday bonus active weekends starting Thanksgiving and then every day from December 20 to January 2.
              </Tooltip>
            )}

            {displayStatBonusTooltip && (
              <Tooltip
                arrowClassName="tooltip-arrow-light"
                fade={false}
                innerClassName="tooltip-inner-light"
                isOpen
                placement="bottom"
                target="stat-bonus-badge"
              >
                ${CONFIG.STAT_OFF_HOUR_BONUS} for STAT bonus active during weekends.
              </Tooltip>
            )}
          </div>

          <div className="d-flex gap-15px align-items-center mt-1">
            <p className="text-s m-0 hide-mobile">
              All unclaimed cases will automatically appear below. Claim them here or from within the queue.
            </p>

            <Spinner size="sm" color="primary" className={`${isQueryingVetStatus ? 'opacity-1' : 'opacity-0'} transition-f`} />
          </div>
        </div>

        <div className="ml-4 d-flex align-items-start">
          {isQuerying[QueryName.DownloadStudies] ? (
            <div className="d-flex">
              <Spinner size="sm" color="dark" />
              <p className="text-s ml-2">Downloading images...</p>
            </div>
          ) : (
            <Button className="hide-mobile nowrap" disabled={!claimedConsultations?.length} size="sm" onClick={downloadImages}>
              Download images
            </Button>
          )}

          <Button
            disabled={!consultations.length}
            size="lg"
            className="nowrap ml-3"
            onClick={() => navigate('/consultations')}
            color="primary"
          >
            Read cases
          </Button>
        </div>
      </div>

      {incompleteConsultationAddendums.length > 0 && (
        <div>
          {incompleteConsultationAddendums.map((i) => (
            <Badge
              onClick={() => navigate(`/consultations?i=${i.consultation.id}`)}
              style={{ borderRadius: '50px' }}
              className="py-1 px-3 bg--primary shadow-sm mb-1 d-flex align-items-center width-fit-content pointer"
            >
              Addendum for {i.consultation.case.patient.display_name} requested {moment(i.created_at).fromNow()} (case read by{' '}
              {vetNameWithoutQualifications(i.receiving_vet.display_name)})
              <img className="ml-2 icon-xs" src={arrowRightWhite} />
            </Badge>
          ))}
        </div>
      )}

      <ClaimTable
        dark={props.dark}
        data={orderConsultations(consultations)}
        handleClickedAiAssessment={(id: number) => setDisplayAiAssessmentCaseId(id)}
      />

      {vetTasks.length > 0 && isRadimalEnterprise && user && !EXCLUDE_AI_TASKS_VET_IDS.includes(user.id) && (
        <div className="mt-5 border p-4 rounded max-width-900px">
          <h4 className="bold">AI Tasks</h4>
          <TrainingIterationsTable dark={props.dark} data={vetTasks.slice(0, 10)} />
        </div>
      )}
    </div>
  )
}
