import React from 'react'

import { palette } from '../../lib/cssHelpers'

interface MiniStepProps {
  children: any
  idx: number
}

export default (props: MiniStepProps) => (
  <div className="d-flex align-items-start my-3">
    <div
      style={{ backgroundColor: palette.gray1 }}
      className="rounded rounded-circle icon-xs d-flex align-items-center justify-content-center mr-3 flex-shrink-0"
    >
      <p className="text-xs m-0 gray5">{props.idx}</p>
    </div>

    {props.children}
  </div>
)
