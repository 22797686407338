import React from 'react'

import { palette } from '../../lib/cssHelpers'

interface Props {
  minutes: number
  title: string
  children: any
  progress: number
}

export default function Chapter(props: Props) {
  const { title, minutes, children, progress } = props

  const complete = progress === 100

  return (
    <div style={{ maxWidth: '900px' }} className="mt-4 border border-secondary rounded box-shadow-gray">
      <div className="d-flex align-items-center justify-content-between px-3 py-4">
        <h6 className="m-0">{title}</h6>

        <p className={`m-0 ${complete && 'text-success'}`}>{complete ? 'All steps completed!' : `About ${minutes} min`}</p>
      </div>

      <div
        style={{ height: '5px', backgroundColor: palette.gray1, marginLeft: '-1px', width: 'calc(100% + 2px)' }}
        className="position-relative"
      >
        <div
          style={{ height: '5px', backgroundColor: palette.success, width: `${progress}%` }}
          className="position-absolute t-0 l-0 transition-m"
        />
      </div>

      <div className="p-3">{children}</div>
    </div>
  )
}
