import React from 'react'

export default (
  <div>
    <h6 className="bold mt-4">Contact Raymax</h6>
    <div>Raymax will assist with your setup. Ask them to configure a new DICOM destination.</div>
    <br></br>
    <br></br>
    <div>
      <b>Raymax Technical Support</b>
    </div>
    <div>(647)971-9729</div>
    <div>
      <a href="mailto:support@raymaxmedical.com">support@raymaxmedical.com</a>
    </div>
    <br></br>
    <br></br>
    <div>
      <p className="m-0">Server Information</p>
      <ul className="m-0">
        <li>Server Name - Radimal</li>
        <li>AE Title - RADIMAL</li>
        <li>IP Address - 34.192.244.223</li>
        <li>Port - 4242</li>
      </ul>
    </div>
  </div>
)
