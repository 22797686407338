import React, { useCallback, useEffect } from 'react'
import first from 'lodash/first'
import { Button, Modal, Spinner } from 'reactstrap'
import { useDropzone } from 'react-dropzone'

import { QueryName } from '../../hasura/queryNames'
import { casesSelector, CasesState, postInstanceAction, setPostInstanceResultAction } from '../../hasura/slices/cases'
import { trackHotjarEvent } from '../../lib/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { usersSelector, UsersState } from '../../hasura/slices/users'
import { insertOrganizationEventForUserAction } from '../../hasura/slices/organizations'
import { OrganizationEvent } from '../../lib/organizationEventTypes'

// @ts-ignore
import uploadIcon from '../../lib/images/upload.png'

interface Props {
  setDisplayUploadDicomsModal: (displayUploadDicomsModal: boolean) => void
}

export default function UploadDicomsModal(props: Props) {
  const dispatch = useDispatch()

  const { accessToken, user }: UsersState = useSelector(usersSelector)
  const { postInstanceResult, isQuerying }: CasesState = useSelector(casesSelector)

  useEffect(() => {
    dispatch(setPostInstanceResultAction(undefined))
    dispatch(insertOrganizationEventForUserAction(accessToken, OrganizationEvent.OpenedUploadImage, user?.organization.id))
  }, [])

  const onDrop = useCallback(async (acceptedFiles: any[], fileRejections: any[]) => {
    trackHotjarEvent('attempted_upload_dicom')

    dispatch(
      insertOrganizationEventForUserAction(accessToken, OrganizationEvent.AttemptedUploadDicom, user?.organization.id, {
        acceptedFiles: acceptedFiles.map((a) => a.name),
        fileRejections: fileRejections.map((f) => f.file?.name),
      })
    )

    if (fileRejections.length || !acceptedFiles.length) {
      const type = first(fileRejections)?.file?.type
      return dispatch(
        setPostInstanceResultAction([
          false,
          `Incorrect file type${type ? ` (${type})` : ''}. Please upload a file with a .dcm extension.`,
        ])
      )
    }

    if (acceptedFiles.length > 1) {
      return dispatch(setPostInstanceResultAction([false, `Please upload one DICOM file at a time.`]))
    }

    const dicomServer = first(user?.organization.dicom_servers)
    if (!dicomServer) return

    const formData = new FormData()
    acceptedFiles.forEach((file: any) => formData.append('files[]', file))
    dispatch(postInstanceAction(formData, dicomServer.ip_address, dicomServer.aet || ''))
  }, [])

  const onDragEnter = useCallback(() => dispatch(setPostInstanceResultAction(undefined)), [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: '.dcm', onDragEnter })

  const highlight = isDragActive || isQuerying[QueryName.PostInstance]

  return (
    <Modal className="upload-modal" centered isOpen toggle={() => props.setDisplayUploadDicomsModal(false)}>
      <div className={`transition-m rounded ${highlight ? 'bg--primary2' : ''}`} {...getRootProps()}>
        {isQuerying[QueryName.PostInstance] ? (
          <div style={{ minHeight: '324px' }} className="flex-center m-3">
            <Spinner color="primary" />
          </div>
        ) : (
          <div
            style={{ minHeight: '324px' }}
            className={`border-upload ${
              highlight ? 'border-upload__drag-active' : ''
            } rounded py-5 m-3 flex-center flex-column text-center`}
          >
            <input {...getInputProps()} />

            <img className="icon-l" src={uploadIcon} />

            <div className="mt-1 mb-4 text-center">
              <h3 className="text-xl mt-3 mb-2 bold text-primary">DICOM Upload</h3>

              <p className="text-m m-0 gray6">Upload or drag and drop a DICOM.</p>
            </div>

            <Button color="primary" outline>
              Upload
            </Button>

            {postInstanceResult && (
              <p className={`px-5 mt-3 text--${postInstanceResult[0] ? 'success' : 'danger'} mb-0 semibold text-s`}>
                {postInstanceResult[1]}
              </p>
            )}
          </div>
        )}
      </div>
    </Modal>
  )
}
