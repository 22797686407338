import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import extend from 'lodash/extend'
import moment from 'moment'
import pick from 'lodash/pick'
import styled from 'styled-components'
import { Button } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useIntercom } from 'react-use-intercom'

import StepComponent from './step'
import IdexxWebSteps from './dicomTechnologies/idexxWeb'
import IdexxDesktopSteps from './dicomTechnologies/idexxDesktop'
import RaymaxSteps from './dicomTechnologies/raymax'
import eFilmSteps from './dicomTechnologies/eFilm'
import VXVueSteps from './dicomTechnologies/VXVue'
import OtherSteps from './dicomTechnologies/other'
import { DicomServerSuggestions_dicom_servers } from '../../hasura/graphQlQueries/types/DicomServerSuggestions'
import { Option } from '../../lib/helpers'
import { Step } from '.'
import { palette } from '../../lib/cssHelpers'
import { usersSelector, UsersState } from '../../hasura/slices/users'

import {
  DicomServersState,
  dicomServerSuggestionsAction,
  dicomServersSelector,
  updateDicomServerAction,
} from '../../hasura/slices/dicom-servers'

// @ts-ignore
import computer from '../../lib/images/computer.png'
// @ts-ignore
import question from '../../lib/images/question.png'

const HUBSPOT_IFRAME = `<div class="meetings-iframe-container" data-src="https://meetings.hubspot.com/radimal/setup?embed=true"></div>`
const HUBSPOT_SCRIPT_SRC = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js'

enum DicomTechnology {
  IdexxWeb = 'IDEXX Web',
  IdexxDesktop = 'IDEXX Desktop',
  Raymax = 'Raymax',
  eFilm = 'eFilm',
  VXVue = 'VXVue',
  Other = 'Other',
}

enum DicomSetupPreference {
  Self = 'Self set-up',
  Assisted = 'With help',
}

interface Props {
  completed: Step[]
  step?: Step
  setStep: (step: Step) => void
}

const dicomOptions: Option[] = [
  { label: DicomTechnology.IdexxWeb, value: DicomTechnology.IdexxWeb },
  { label: DicomTechnology.IdexxDesktop, value: DicomTechnology.IdexxDesktop },
  { label: DicomTechnology.eFilm, value: DicomTechnology.eFilm },
  { label: DicomTechnology.VXVue, value: DicomTechnology.VXVue },
  { label: DicomTechnology.Raymax, value: DicomTechnology.Raymax },
  { label: DicomTechnology.Other, value: DicomTechnology.Other },
]

export default function DicomSetupSteps(props: Props) {
  const dispatch = useDispatch()

  const { showNewMessages } = useIntercom()

  const { accessToken, user }: UsersState = useSelector(usersSelector)
  const { dicomServersSuggestions }: DicomServersState = useSelector(dicomServersSelector)

  const [dicomSetupPreference, setDicomSetupPreference] = useState<DicomSetupPreference | undefined>()
  const [dicomTechnology, setDicomTechnology] = useState<Option | undefined>()
  const [dicomServerNotFound, setDicomServerNotFound] = useState(false)

  /* 
    Effects
  */

  useEffect(() => {
    if (!dicomSetupPreference) return

    window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight)

    if (dicomSetupPreference === DicomSetupPreference.Assisted) addHubSpotScript()
  }, [dicomSetupPreference, dicomTechnology])

  useEffect(() => {
    const interval = setInterval(pollSuggestions, 10 * 1000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    const noSuggestions = dicomServersSuggestions?.length === 0
    if (noSuggestions && dicomServerNotFound) switchToAssisted()
  }, [dicomServersSuggestions, dicomServerNotFound])

  /* 
    Methods
  */

  const pollSuggestions = (sinceDays = 3) =>
    dispatch(dicomServerSuggestionsAction(accessToken, moment().subtract(sinceDays, 'days').utc().format()))

  const addHubSpotScript = () => {
    const script = document.createElement('script')
    script.src = HUBSPOT_SCRIPT_SRC
    document.body.appendChild(script)
  }

  const handleClickedSuggestion = (suggestion: DicomServerSuggestions_dicom_servers) => {
    const variables = extend(pick(suggestion, ['id', 'aet', 'ip_address']), { organization_id: user?.organization.id })
    dispatch(updateDicomServerAction(accessToken, variables))
  }

  const switchToAssisted = () => {
    setDicomSetupPreference(DicomSetupPreference.Assisted)
    setDicomServerNotFound(false)
  }

  // @ts-ignore
  const steps = {
    [DicomTechnology.IdexxWeb]: IdexxWebSteps,
    [DicomTechnology.IdexxDesktop]: IdexxDesktopSteps,
    [DicomTechnology.VXVue]: VXVueSteps,
    [DicomTechnology.eFilm]: eFilmSteps,
    [DicomTechnology.Raymax]: RaymaxSteps,
    [DicomTechnology.Other]: OtherSteps,
  }[dicomTechnology?.value]

  return (
    <div className="p-1">
      <StepComponent
        idx={1}
        selected={props.step}
        setStep={props.setStep}
        step={Step.XraySetup}
        completed={props.completed.includes(Step.XraySetup)}
        children={
          <div onClick={(e) => e.stopPropagation()} className="mt-4">
            <p className="text-m">
              Connecting your x-ray technology lets Radimal instantly receive and diagnose your patients' images.
            </p>

            {dicomSetupPreference !== DicomSetupPreference.Assisted && (
              <div
                style={{ width: 'fit-content', backgroundColor: palette.gray1, maxWidth: '450px' }}
                className="my-3 rounded py-3 px-4 m-0-auto"
              >
                <div className="d-flex align-items-center justify-content-center">
                  <div className="pulse-circle" />

                  <p className="ml-1 mb-0 text-m">Waiting for your first patient. Please send a test case.</p>
                </div>

                {dicomServerNotFound && dicomServersSuggestions?.length ? (
                  <div className="mt-3 text-center">
                    <p className="text-s mb-2 font-italic gray8">Are any of the following your patients?</p>

                    <div className="d-flex flex-wrap justify-content-center">
                      {dicomServersSuggestions.map((suggestion) => (
                        <Button
                          onClick={() => handleClickedSuggestion(suggestion)}
                          key={suggestion.id}
                          className="m-1"
                          color="primary"
                          size="sm"
                        >
                          {suggestion.first_study_patient_name}
                        </Button>
                      ))}

                      <Button onClick={switchToAssisted} className="m-1" outline color="primary" size="sm">
                        None
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div
                    onClick={() => setDicomServerNotFound(true)}
                    style={{ maxWidth: '400px' }}
                    className="mt-3 text-s font-italic gray8 text-center px-4"
                  >
                    <p className="mb-1">Having trouble?</p>

                    <p className="m-0">
                      If you followed the self set-up steps below and are still waiting then{' '}
                      <span className="text-link">click here</span>.
                    </p>
                  </div>
                )}
              </div>
            )}

            <div>
              <p className="text-m bold mb-1">Choose how you'd like to get set up:</p>

              <div role="button" className="d-flex">
                <LargeButton
                  isSelected={dicomSetupPreference === DicomSetupPreference.Self}
                  onClick={() => {
                    setDicomSetupPreference(DicomSetupPreference.Self)
                  }}
                  style={{ borderRadius: '5px 0 0 5px' }}
                >
                  <img style={{ height: '45px', width: 'auto' }} src={computer} />
                  <p className="bold m-0 gray8 mt-1">{DicomSetupPreference.Self}</p>
                </LargeButton>

                <LargeButton
                  isSelected={dicomSetupPreference === DicomSetupPreference.Assisted}
                  onClick={() => {
                    setDicomSetupPreference(DicomSetupPreference.Assisted)
                  }}
                  style={{ borderRadius: '0 5px 5px 0', marginLeft: '-1px' }}
                >
                  <img style={{ height: '45px', width: 'auto' }} src={question} />
                  <p className="bold gray8 m-0 mt-1">{DicomSetupPreference.Assisted}</p>
                </LargeButton>
              </div>
            </div>

            {dicomSetupPreference === DicomSetupPreference.Assisted && (
              <div className="mt-4">
                <p className="m-0">
                  Pick a time when we can assist you getting setup or
                  <span onClick={() => showNewMessages()} className="text-link mx-1">
                    click here
                  </span>
                  and send us a message now.
                </p>

                <div style={{ display: 'inline' }} dangerouslySetInnerHTML={{ __html: HUBSPOT_IFRAME }} />
              </div>
            )}

            {dicomSetupPreference === DicomSetupPreference.Self && (
              <div className="mt-3">
                <p className="text-m bold mb-1">Choose your x-ray technology provider:</p>

                <Select
                  menuPlacement="top"
                  className="react-select-container z-100"
                  classNamePrefix="react-select"
                  value={dicomTechnology}
                  options={dicomOptions}
                  onChange={(option: any) => setDicomTechnology(option)}
                />

                <div className="text-s">{steps}</div>
              </div>
            )}
          </div>
        }
      />
    </div>
  )
}

interface LargeButtonProps {
  isSelected: boolean
}

const LargeButton = styled.div`
  color: ${(p: LargeButtonProps) => (p.isSelected ? palette.primary : palette.gray8)};
  background-color: ${(p: LargeButtonProps) => (p.isSelected ? palette.primary2 : 'white')};
  max-width: 325px;
  width: 100%;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(p: LargeButtonProps) => (p.isSelected ? palette.primary : palette.gray1)};
  z-index: ${(p: LargeButtonProps) => (p.isSelected ? 100 : 0)};
`
