import React from 'react'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'

import { Case_cases } from '../../hasura/graphQlQueries/types/Case'
import { numberWithCommas } from '../../lib/helpers'

interface Props {
  cases: Case_cases[]
  clickedNewer: () => void
  clickedNewest: () => void
  clickedOlder: () => void
  cursor?: number
  loading: boolean
  previousCursors: number[]
  rowsPerPage: number
  totalCount: number
}

export default function Cases(props: Props) {
  const { cases, cursor, rowsPerPage, totalCount, previousCursors, loading } = props

  const firstIndex = cursor ? (previousCursors.length + 1) * rowsPerPage : 0

  return (
    <div className="d-flex align-items-end">
      <Pagination className="mr-2" size="sm">
        <PaginationItem onClick={props.clickedNewest} disabled={!cursor || loading}>
          <PaginationLink first>Newest</PaginationLink>
        </PaginationItem>

        <PaginationItem onClick={props.clickedNewer} disabled={!cursor || loading}>
          <PaginationLink previous>Newer</PaginationLink>
        </PaginationItem>

        <PaginationItem disabled={loading || cases.length < rowsPerPage} onClick={props.clickedOlder}>
          <PaginationLink next>Older</PaginationLink>
        </PaginationItem>
      </Pagination>

      <p className="m-0 text--gray8 text-s">
        Displaying {firstIndex + 1} - {Math.min(firstIndex + cases.length, totalCount)} of {numberWithCommas(totalCount)}.
      </p>
    </div>
  )
}
